body {
	font-family: $font-body !important;
	color: #231f20;
}

h1, h2, h3, h4, h5, h6 {
	font-family: $font-headers;
}

body.blog {
	padding-top: 120px;
	@include breakpoint(medium down) {
		padding-top: 0px;
	}
}

h3, h4, h5 {
	font-family: $font-headers;
	// text-transform: uppercase;
	color: #231f20;
}

.step-box {
	text-align: center;
	font-size: 35px;
	color: #94c022;
	border: 3px solid #94c022;
	font-weight: bold;
	border-radius: 35px;
	width: 65px;
	height: 65px;
	display: block;
	margin: 0 auto;
}

.main-wrap {
	@include xy-grid-container;
	@include xy-grid();
	margin-top: 1rem;

	@include breakpoint(medium) {
		margin-top: 3rem;
	}

	&.sidebar-left {

		// Place the sidebar below main content on small screens ...
		@include breakpoint(small) {
			.main-content { order: 1; }
			.sidebar { order: 2; }
		}

		// ... and to the left on medium-up screens, when using the sidebar-left template
		@include breakpoint(medium) {
			.main-content { order: 2; }
			.sidebar { order: 1; }
		}
	}

	// Full width template
	&.full-width {
		.main-content {
			@include xy-cell(12);
		}
	}

	.main-content {

		// Default template
		@include breakpoint(small) {
			@include xy-cell(12);
		}

		@include breakpoint(medium) {
			@include xy-cell(8);
		}

	}

	.sidebar {

		@include breakpoint(small) {
			@include xy-cell(12);
		}

		@include breakpoint(medium) {
			@include xy-cell(4);
			margin-top: 1rem;
		}

	}
}

.wrap {
	padding-top: 60px;
	padding-bottom: 60px;
}

.box-inner-inner {
	padding: 20px;
	h3 {
		line-height: 1.0;
	}
}

.box--left {
	width: 44%;
	@include breakpoint(medium down) {
		vertical-align: middle;
	}
}
.box--right {
	width: 55%;
	color: white;
	font-weight: bold;
}
.box-inner {
	position: absolute;
	top: 50%;
	margin-top: -150px;
	margin-left: 20px;
	@include breakpoint(large down) {
		margin-top: -150px;
	}
	@include breakpoint(medium down) {
		position: static;
		margin-top: -20px;
	}

}
.hero-title_small {
	font-size: 40px;
	color: #e5e1e4;
	@include breakpoint(large down) {
		font-size: 20px;
	}
}

.hero-wrap {
	background-size: cover;
	background-position: center;
	height: 100vh;
	width: 100%;
	color: $white;
	display: table;
	position: relative;
	-webkit-box-shadow: 0px 2px 20px 0px rgba(204,204,204,0.98);
	-moz-box-shadow: 0px 2px 20px 0px rgba(204,204,204,0.98);
	box-shadow: 0px 2px 20px 0px rgba(204,204,204,0.98);
	&:before {
		content: '';
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
	}
	@include breakpoint(large down) {
		height: 55vh;
	}
	@include breakpoint(medium down) {
		display: none;
	}
	.hero_inner {
		display: table-cell;
		vertical-align: middle;
		text-align: center;
		.box--inner {
			margin-top: 45px;
			margin-bottom: 45px;
		}
		.hero-title {
			font-size: 70px;
			font-family: $font-headers;
			color: #e5e1e4;
			@include breakpoint(medium down) {
				font-size: 30px;
			}
		}
		p {
			color: #00a381;
			font-weight: bold;
			font-size: 22px;
			padding-top: 10px;
			padding-bottom: 10px;
			@include breakpoint(large down) {
				font-size: 14px;
			}
		}
		.company--logo {
			width: 200px;
			padding-top: 60px;
			@include breakpoint(large down) {
				width: 100px;
			}
		}
		.button {
			/* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#00b9e7+10,00b37d+90 */
			background: #00b9e7; /* Old browsers */
			background: -moz-linear-gradient(left, #00b9e7 10%, #00b37d 90%); /* FF3.6-15 */
			background: -webkit-linear-gradient(left, #00b9e7 10%,#00b37d 90%); /* Chrome10-25,Safari5.1-6 */
			background: linear-gradient(to right, #00b9e7 10%,#00b37d 90%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
			filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00b9e7', endColorstr='#00b37d',GradientType=1 ); /* IE6-9 */
			color: white;
			border-radius: 10px;
			padding: 12px 48px;
			font-size: 16px;
			font-weight: 200;
			text-decoration: none;
			box-shadow: 0 16px 32px 0 rgba(0, 40, 120, .35);
			transition: all 0.5s ease;
			opacity: 1;
			z-index: 5;
			position: relative;
		}

		.button:hover {
			opacity: 0.7;
		}
	}
}

@media screen and (max-width: 1200px) {
	.hero-title {
		font-size: 48px;
	}
	.hero-wrap .hero_inner p {
		font-size: 18px;
	}
	.hero-wrap .hero_inner p {
		font-size: 22px;
	}
}

#menu-item-21, #menu-item-1549 {
	color: white !important;
	a {
		/* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#00b9e7+10,00b37d+90 */
		background: #00b9e7; /* Old browsers */
		background: -moz-linear-gradient(left, #00b9e7 10%, #00b37d 90%); /* FF3.6-15 */
		background: -webkit-linear-gradient(left, #00b9e7 10%,#00b37d 90%); /* Chrome10-25,Safari5.1-6 */
		background: linear-gradient(to right, #00b9e7 10%,#00b37d 90%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
		filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00b9e7', endColorstr='#00b37d',GradientType=1 ); /* IE6-9 */
		color: white !important;
		border-radius: 10px;
		padding: 12px 48px !important;
		font-size: 16px;
		font-weight: 200;
		text-decoration: none;
		box-shadow: 0 16px 32px 0 rgba(0, 40, 120, .35);
		transition: all 0.5s ease;
		opacity: 1;
	}

	a:hover {
		opacity: 0.7;
	}
}

.gallery--images {
	height: 400px;
}

.dlakogo-img {
	height: 200px;
	width: 100%;
	background-size: cover;
	margin-bottom: 15px;
}

.diety-wrap {
	background: url(../images/meal-bg.jpg);
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center;
}

.img_small {
	width: 140px;
	border-radius: 50px;
	margin-right: 15px;
}

#map {
	width: 100%;
	height: 440px;
}

.cta--wrap {
	background: map-get($foundation-palette, primary);
	padding-top: 60px;
	padding-bottom: 60px;
	.button {
		background: $white;
		border-color: $white;
		color: map-get($foundation-palette, primary);
	}
}

.inline-howto {
	.icon-example {
		margin-right: 0px;
		padding-bottom: 10px;
		@include breakpoint(medium down) {
			width: 30px;
			height: 30px;
			margin-right: 10px;
		}
	}
}

.icon-example {
	margin-right: 10px;
}

.box--inner {
	display: table;
	min-height: 50px;
	.box--left, .box--right {
		display: table-cell;
		vertical-align: middle;
	}
}

.company--logo {
	display: block;
	margin: 0 auto;
	padding-bottom: 15px;
}

.inline-howto_mobile {
	display: none;
	@include breakpoint(medium down) {
		display: block;
	}
}

.description {
	padding: 10px;
	margin-top: 20px;
	position: relative;
	font-weight: normal;
	z-index: 1;
	text-align: center;
	p {
		margin-top: 15px;
		@include breakpoint(large down) {
			height: 100px;
		}
	}
	p:nth-child(2) {
		display: none;
	}
	h4 {
		font-size: 14px;
		color: #D6D6D6;
		text-transform: uppercase;
	}
}
.description::before {
	content: '';
	width: 0;
	height: 0;
	position: absolute;
	top: -5px;
	left: 43%;
}


.description h4 {
	@include breakpoint(small down) {
		margin-bottom: 15px;
	}
}

.kontakt--wrap_page a {
	color: $motyw-przewodni;
}

.main-recommendation {
	margin-bottom: 30px;
	.img_small {
		width: 160px;
		border-radius: 81px;
		display: block;
		margin: 0 auto;
	}
	.rekomendacje-box p {
		font-size: 18px;
	}
}

.rekomendacje-box {
	padding-top: 15px;
	padding-bottom: 15px;
	p {
		font-size: 14px;
	}
	@include breakpoint(large down) {
		min-height: 120px;
	}
	@include breakpoint(small down) {
		min-height: 80px;
	}
}

.inline-howto_box {
	@include breakpoint(medium down) {
		display: none;
	}
}

.tabs .tabs-title > a:focus, .tabs .tabs-title > a[aria-selected='true'] {
	background: transparent;
	padding-left: 0;
	padding-right: 0;
	border-bottom: 2px solid $motyw-przewodni;
}
.tabs-title {
	margin-right: 25px;
	@include breakpoint(small down) {
		margin-right: 20px;
	}
}
.tabs-title a {
	padding-left: 0;
	padding-right: 0;
	padding-bottom: 10px;
	border-bottom: 2px solid transparent;
}

.tabs {
	border: none;
	background: transparent;
	a {
		font-family: $font-headers;
		// text-transform: uppercase;
		font-size: 18px;
		color: $font-color !important;
		@include breakpoint(small down) {
			font-size: 12px;
		}
	}

}

.page-template-page-diety {
	.tabs a {
		font-size: 14px;
		text-transform: initial;
	}
}

.tabs-panel {
	padding-left: 0;
}

.tabs-content {
	border: none !important;
	background: transparent !important;

}

.accordion-content {
	padding-left: 0;

	@include breakpoint(large down) {
		// white-space:nowrap;
		overflow-x:auto;
	}

}

:last-child > .accordion-content:last-child {
	border-bottom: 0px solid #e6e6e6;
}

:last-child:not(.is-active) > .accordion-title {
	border-bottom: 0px solid #e6e6e6;
	border-radius: 0 0 0 0;
}

.accordion-title:hover, .accordion-title:focus {
	background-color: transparent;
}

.accordion-content {
	border: none;
}

.accordion-title{
	border: 0px solid #e6e6e6;
	left: 20px;
}

.accordion-title::before {
	position: absolute;
	top: 50%;
	left: -8px;
	margin-top: -14px;
	content: '+';
	margin-left: -10px;
	background: transparent;
	width: 22px;
	height: 28px;
	font-size: 28px;
	padding-left: 4px;
	color: $motyw-przewodni;
}

.is-active > .accordion-title::before {
	content: '\2013';
	padding-left: 2px;
}

.accordion-title {
	text-transform: uppercase;
	// font-size: 19px;
	color: $font-color !important;
}

.button {
	/* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#00b9e7+10,00b37d+90 */
	background: #00b9e7; /* Old browsers */
	background: -moz-linear-gradient(left, #00b9e7 10%, #00b37d 90%); /* FF3.6-15 */
	background: -webkit-linear-gradient(left, #00b9e7 10%,#00b37d 90%); /* Chrome10-25,Safari5.1-6 */
	background: linear-gradient(to right, #00b9e7 10%,#00b37d 90%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00b9e7', endColorstr='#00b37d',GradientType=1 ); /* IE6-9 */
	color: white;
	border-radius: 10px;
	padding: 12px 48px;
	font-size: 16px;
	font-weight: bold !important;
	text-decoration: none;
	transition: all 0.5s ease;
	opacity: 1;
}

.button:hover {
	opacity: 0.7;
}

.logo--bg {
	display: table;
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center;
	.text--inner {
		display: table-cell;
		vertical-align: middle;
		padding: 30px;
	}
}

.margin-top {
	margin-top: 30px;
}

.margin-bottom {
	margin-bottom: 30px;
}

.company--header {
	width: 45px;
	@include breakpoint(large down) {
		width: 60px;
	}
}

.not-front {
	background: #1B1919;
	padding-top: 15px;
	padding-bottom: 15px;

	.menu--wrap {
		display: table;
		.top--menu {
			display: table-cell;
			vertical-align: middle;
		}
	}
}

iframe {
	width: 100%;
}

.blog--title a {
	font-family: $font-headers;
	text-transform: uppercase;
	font-size: 36px;
	color: $font-color;
}

.updated {
	font-family: $font-headers;
	text-transform: uppercase;
	font-size: 20px;
	color: $motyw-przewodni;
}

.tags--wrap {
	color: $motyw-przewodni;
	font-size: 13px;
	text-transform: uppercase;
	margin-top: 15px;
}
.tags--wrap a {
	color: $motyw-przewodni;
	font-size: 13px;
	text-transform: uppercase;
}

.entry-content p {
	color: $font-color;
}

.ssba.ssba-wrap {
	margin-top: 15px;
	margin-bottom: 30px;
}

.centered {
	list-style-type: none;
	text-align: center;
	li {
		display: inline-block;
		padding: 30px;
	}
}

.faq--page {
	background: url(../images/faq-bg.jpg);
	background-size: cover;
	background-position: center;
	h3 {
		text-transform: inherit;
		letter-spacing: 1px;
	}

	.accordion {
		background: transparent;
		margin-top: 30px;
	}

	.accordion-content {
		background-color: transparent;
	}
	.accordion-item {
		position: relative;
		.faq--number {
			font-size: 22px;
			color: $motyw-przewodni;
			position: absolute;
			left: -16px;
			top: 0px;
		}
	}
	.accordion-title {
		font-family: $font-headers;
		font-size: 20px;
		padding-top: 30px;
		padding-bottom: 30px;
	}
}

.accordion-title {
	font-family: $font-headers;
	font-size: 18px;
	padding-top: 30px;
	padding-bottom: 30px;
}

table thead {
	background: transparent;
	color: #0a0a0a;
}

table thead, table tbody, table tfoot {
	border: 0px solid #f1f1f1;
	background-color: transparent;
}

table tbody tr:nth-child(even) {
	border-bottom: 0;
	background-color: transparent;
}

.wrap_small {
	padding-bottom: 30px;
}

.recommend--name {
	font-size: 14px;
	font-family: $font-headers;
	color: $font-color;
}

.menu--wrap {
	display: table;
	padding-top: 10px;
	padding-bottom: 10px;
	.inner {
		display: table-cell;
		vertical-align: middle;
	}
}

.img--bg {
	min-height: 460px;
	width: 100%;
}

#mc4wp-form-1 input[type="submit"] {
	background: $button-color;
	font-family: $font-headers;
	text-transform: uppercase;
	font-size: 14px;
	border-radius: 5px;
	border-width: 0px;
	padding: 0.85em 2em;
	color: #fff;
	&:hover {
		background: darken($button-color, 10%);
	}
}

#mc4wp-form-1 [type='text'], [type='password'], [type='date'], [type='datetime'], [type='datetime-local'], [type='month'], [type='week'], [type='email'], [type='number'], [type='search'], [type='tel'], [type='time'], [type='url'], [type='color'], textarea {
	background-color: transparent;
	color: #fff;
	&:focus {
		background-color: transparent;
	}
}

#mc4wp-form-1 .mc4wp-form-fields {
	text-align: right;
}

.newsletter--box {

	text-align: right;
	float: right;
	@include breakpoint(medium down) {
		float: left;
		text-align: left;
	}
}

#mc4wp-form-1 input[type="submit"]  {
	width: 200px;
}

.dlakogo-wrap {
	p {
		font-size: 14px;
	}
}

.rekomend--link {
	&:hover {
		color: $motyw-przewodni;
	}
}

.countdown-box {
	width: 150px;
	display: block;
	margin: 0 auto;
	padding-top: 30px;
	padding-bottom: 30px;
}

.buttons-box {
	margin-top: 30px;
}

.menu .header--logo {
	width: 45px;
}

.recomend-img {
	border-radius: 90px;
	@include breakpoint(large down) {
		border-radius: 160px;
	}
}


#sposob-dzialania {
	@include breakpoint(medium down) {
		display: none;
	}
}

.oferta-tytul-mobile {
	display: none;
	@include breakpoint(medium down) {
		display: block;
		text-align: center;
	}
}

.border--box {
	border: 2px solid $motyw-przewodni;
	padding: 10px;
	font-weight: bold;
	margin-bottom: 30px;
	margin-top: 30px;
}


#o-nas {
	background: url(../images/onas-bg.jpg);
	background-size: cover;
	background-repeat: no-repeat;
	position: relative;
	color: white;
	&:before {
		content: '';
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		background: rgba(0, 0, 0, 0.3);
		z-index: 1;
	}
	.my-img {
		position: relative;
		z-index: 1;
	}
	.text--inner {
		position: relative;
		z-index: 1;
		font-weight: bold;
	}

	h3, p {
		color: white;
	}
	.text--wrap {
		min-height: 400px;
		display: table;
		width: 100%;
	}
	.text--inner {
		display: table-cell;
		vertical-align: middle;
	}
}

.blue-header {
	color: $motyw-przewodni;
	font-size: 18px;
	text-transform: capitalize;
}

.sidebar {
	h6 {
		text-transform: uppercase;
		color: $motyw-przewodni;
	}
	ul {
		list-style: none;
		margin-left: 0;
		li {
			margin-bottom: 10px;
		}
		a {
			color: $font-color;
		}
	}
}

.related-posts {
	padding-top: 15px;
	border-top: 2px solid $motyw-przewodni;
	h5 {
		color: $motyw-przewodni;
	}

	.wysrodkowana-lista-inline {
		list-style-type: none;
		text-align: center;
		li {
			display: inline-block;
			padding: 10px;
			a {
				color: $font-color;
				padding: 15px;
				text-transform: uppercase;
				&:hover {
					color: $motyw-przewodni;
				}
			}
		}
	}
}

.post-list {
	li {
		margin-bottom: 10px;
		a {
			color: $motyw-przewodni;
			text-transform: uppercase;
			padding-left: 0;
			&:hover {
				text-decoration: underline;
			}
		}

	}
}

.wrap-lg {
	padding-top: 120px;
	padding-bottom: 120px;
}


.dropdown.menu > li.is-dropdown-submenu-parent > a::after {
	right: -10px !important;
}

.js-dropdown-active {
	z-index: 9999;
}

.is-dropdown-submenu {
	li:hover a {
		color: $motyw-przewodni !important;
		text-decoration: underline !important;
		font-weight: bold !important;
	}
}

#search-results {
	h1.entry-title {
		padding-top: 15px;
		padding-bottom: 15px;
	}
}

.has-cookie-bar #catapult-cookie-bar {
	background: white !important;
	text-align: center !important;
	color: black !important;
	padding: 50px !important;
	p, a {
		color: black !important;
	}
	button#catapultCookie {
		background: #00b9e7; /* Old browsers */
		background: -moz-linear-gradient(left, #00b9e7 10%, #00b37d 90%) !important; /* FF3.6-15 */
		background: -webkit-linear-gradient(left, #00b9e7 10%,#00b37d 90%) !important; /* Chrome10-25,Safari5.1-6 */
		background: linear-gradient(to right, #00b9e7 10%,#00b37d 90%) !important; /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
		filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00b9e7', endColorstr='#00b37d',GradientType=1 ) !important; /* IE6-9 */
		color: white !important;
		border-radius: 10px !important;
		padding: 12px 48px !important;
		font-size: 16px !important;
		font-weight: bold !important;
		text-decoration: none !important;
		transition: all 0.5s ease !important;
		opacity: 1 !important;
		&:hover {
			opacity: 0.7 !important;
		}
	}
}
