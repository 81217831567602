.sites-button {
  background: $primary-color;
  font-size: 1.25rem;
  font-weight: bold;
  box-shadow: 0 -2px 0 rgba(0,0,0,0.2) inset;
  border-radius: 3px;
  border: none;
}


#fb-share-button {
    background: #3b5998;
    border-radius: 3px;
    font-weight: 600;
    padding: 5px 8px;
    display: inline-block;
    position: static;
}

#fb-share-button:hover {
    cursor: pointer;
    background: #213A6F
}

#fb-share-button svg {
    width: 18px;
    fill: white;
    vertical-align: middle;
    border-radius: 2px
}

#fb-share-button span {
    vertical-align: middle;
    color: white;
    font-size: 14px;
    padding: 0 3px
}
