// Navigation breakpoints
.mobile-menu,
.site-title-bar {
	@include hide-for(large);
}

.desktop-menu,
.site-navigation .top-bar-left {
	@include show-for(large);
}

// Site title
.site-desktop-title a {
	font-weight: bold;
}

// Mobile menu
.mobile-menu {
  display: none; // prevents repaint caused by JS hiding menu onload
}



.mobile-menu,
.mobile-off-canvas-menu {

  // submenu arrow
  .is-accordion-submenu-parent {
  	> a::after {
  		border-color: $accordionmenu-arrow-color transparent transparent;
  	}
  	&.active > a::after {
  		border-color: $menu-item-color-active transparent transparent;
  	}
  }

  // current page
  .current_page_item > a {
  	font-weight: bold;
  }
}

// Tablet and desktop menu
.desktop-menu {

  // submenu arrow
  li.is-dropdown-submenu-parent {
  	> a::after {
  		border-color: $accordionmenu-arrow-color transparent transparent;
  	}
  	&.active > a::after {
  		border-color: $menu-item-color-active transparent transparent;
  	}
  }
}

.site-navigation {
	@include breakpoint(small only) {
    padding: 0; // prevents container visibility on small screens
}
}



// WP post navigation
.post-navigation {
	@include clearfix;
}

.nav-previous {
	float:left;
}

.nav-next {
	float:right;
}

.site-navigation.top-bar {
	//background: rgba(0, 0, 0, 0.5);
	border-bottom: 0px solid map-get($foundation-palette, primary);
	// position: fixed;
	// top: 0px;
	// left: 0;
 //    width: 100%;
    z-index: 9;
    padding-top: 15px;
    padding-bottom: 15px;
}

.not-front {
	position: fixed;
	width: 100%;
	top: 0px;
	left: 0;
	z-index: 9;
	@include breakpoint(medium down) {
		display: none;
    }
}
.title-bar {
	background: rgba(255, 255, 255, 0.9);
}

.front-page_menu {
	position: fixed;
	width: 100%;
	top: 0px;
	left: 0;
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center;
	z-index: 9;
	background-color: #fff;
	-webkit-box-shadow: 0px 2px 9px -1px rgba(0,0,0,0.75);
	-moz-box-shadow: 0px 2px 9px -1px rgba(0,0,0,0.75);
	box-shadow: 0px 2px 9px -1px rgba(0,0,0,0.75);
	@include breakpoint(medium down) {
		display: none;
	}
}

ul.dropdown.menu.vertical.submenu.is-dropdown-submenu.first-sub li.is-submenu-item.is-dropdown-submenu-item a {
	text-transform: capitalize !important;
}

.top-bar, .top-bar ul {
	background: transparent;
}


#menu-menu-mobilne li a {
	color: #000;
	font-family: $font-headers;
	text-transform: uppercase;
	padding-left: 2px;
    padding-right: 2px;
    margin-left: 10px;
    margin-right: 10px;
}


#menu-menu-glowne-1 li a {
	font-family: $font-headers;
	text-transform: uppercase;
	padding-left: 2px;
	padding-right: 2px;
	margin-left: 10px;
	margin-right: 10px;
	color: $font-color !important;
	font-size: 15px;
}


#menu-menu-glowne li a {
	// color: #fff;
	font-family: $font-headers;
	text-transform: uppercase;
	padding-left: 2px;
    padding-right: 2px;
    margin-left: 10px;
    margin-right: 10px;
    color: $font-color;
}


#menu-menu-w-stopce li a {
	// color: #fff;
	font-family: $font-headers;
	text-transform: uppercase;
	padding-left: 2px;
    padding-right: 2px;
    margin-left: 10px;
    margin-right: 10px;
    color: $white !important;
}

.menu-item-51, .menu-item-44 {
	a {
		color: $white !important;
		border-radius: 5px !important;
		border: 2px solid $motyw-przewodni;
	    padding-left: 10px !important;
    	padding-right: 10px !important;
    	&:hover {
    		background: $motyw-przewodni;
    	}
	}
}

.title-bar-left {
	position: relative;
	.menu-icon {
		position: absolute;
		right: 15px;
		top: 50%;
		margin-top: -8px;
		z-index: 10;
	}
}

.menu-icon:hover::after {
    background: lighten(#e23462, 10%);
    box-shadow: 0 7px 0 #e23462, 0 14px 0 #e23462;
}

.menu-icon::after {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 2px;
    background: #e23462;
    box-shadow: 0 7px 0 #e23462, 0 14px 0 #e23462;
    content: '';
}

.off-canvas {
	background: #fff;
}


.menu--wrap {
	diplay: table;
	width: 100%;
}
.inner-left {
	display: table-cell;
	vertical-align: middle;
	width: 30%;
}
.inner-right {
	display: table-cell;
	vertical-align: middle;
	width: 70%;

}
