.footer-container {
	margin: 0 auto;
	border-top: 1px solid #979797;
  //margin-top: rem-calc(60);
  background: #0A1117;
  color: $white;

  .footer--menu {
  	display: flow-root;
  }

  .dropdown.menu.desktop-menu a {
  	color: $white;
  	text-transform: uppercase;
  	font-family: $font-headers;
  	font-size: 14px;
  }
  p {
  	font-size: 16px !important;
  }
  .header--logo {
  	width: 45px;
  }
}

.footer {
	.menu--wrap {
		padding-top: 0;
	}
	article {
		@include xy-cell(auto);
		margin: 0;
	}

	ul {
		list-style-type: none;
		margin: 0;
		padding: 0;
	}
}

.menu--wrap ul {
	float: right;
	@include breakpoint(small down) {
		float: left;
	}

}

.kontakt--wrap {
	color: $white;
	font-family: $font-headers;
	font-size: 14px;
}
.kontakt--wrap a {
	color: $motyw-przewodni !important;
	text-transform: lowercase;
}
